<template>
  <Layout>
    <PageHeader class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="updateFeature">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Feature Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model="form.feature"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Feature Icon</label
                      >
                      <input
                        type="file"
                        class="form-control"
                        @change="onFilebanner"
                        id="formrow-Firstname-input"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <img :src="icon_url" height="120px" width="120px"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                  >
                    Update feature
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
  <script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
export default {
  name: "update-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      icon_url:null,
      icon:null,
      form: {
        id: this.$route.params.id,
        feature: null,
      },
    };
  },
  mounted() {
    this.singleFeature();
  },
  methods: {
    singleFeature() {
      this.$axios
        .get("feature/" + this.form.id)
        .then((response) => {
          this.form.feature = response.data.data.feature;
          this.icon_url = response.data.data.image
          //   console.log(response.data.data.feature);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateFeature() {
      let formData = new FormData();
      formData.append('form',JSON.stringify(this.form));
      formData.append('icon',this.icon);
      this.$axios
        .post("feature/update/" + this.form.id, formData)
        .then((response) => {
          this.$router.push({
            name: "feature-list",
          });
          this.triggerSwal(response.data.message, "success");
          console.log(response.data);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.log(error.response);
        });
    },
    onFilebanner(e){
     let file = e.target.files[0];
      this.icon_url = URL.createObjectURL(file)
      this.icon = file;
    }
  },
};
</script>